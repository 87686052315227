import { Button, Card, Flex, Heading, View } from '@aws-amplify/ui-react';
import dayjs from 'dayjs';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useServices } from '../contexts/ServiceContext';

export const WeekAnalytics = ({ onHide }) => {
    const [{ success = [], failed =[] }, setWeekData] = useState({ success: [], failed: [] }) // tODO refactor
    const { firebase } = useServices()
    const isDesktop = window.innerWidth > 1000;

    useEffect(() => {
        const getData = async () => {
            const weekData = await firebase.getWeeklyActivity()
            setWeekData(weekData)
        }

        getData().catch(err => console.log(err))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const renderLogs = (entries, checkKey) => {
        return entries.map((entry, index) => {
            return <div key={index}>
                <div>
                    <div>Scheduled SMS @ {dayjs(entry.request.scheduledAt).format('DD MMMM@HH:mm')}</div>
                    <div>Appointment: {entry.response.startDate}: {entry.response[checkKey]}</div>
                    <br/>
                </div>
            </div>
        })
    }

    return (<View style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            padding: '50px 30px',
            background: 'white',
            opacity: 0.93,
    ...(isDesktop && {
            maxWidth: '600px',
            margin: '0 auto',
        })
    }}>
        <Flex direction="column" justifyContent={'space-around'} width={'100%'}>
            <Heading level={6}>Week Analytics</Heading>

           <Card maxHeight="70vh" overflow="scroll">
               <pre>{renderLogs(success, 'successMessage')}</pre>
               <pre>{renderLogs(failed, 'errorMessage')}</pre>
           </Card>

            <Button
                display="flex"
                shrink="0"
                alignSelf="stretch"
                size="large"
                variation="primary"
                children="Primary Button"
                onClick={() => onHide()}>
                Done
            </Button>
        </Flex>
    </View>)
}

WeekAnalytics.propTypes = {
    logs: PropTypes.arrayOf(PropTypes.any),
    failures: PropTypes.any,
    onCompletion: PropTypes.func,
};
