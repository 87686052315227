import {
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  Link,
  Loader,
  Menu,
  MenuItem,
  Placeholder,
  Text
} from '@aws-amplify/ui-react';
import dayjs from 'dayjs';
import React, {useEffect, useMemo, useState} from 'react';
import {googleLogout, useGoogleLogin} from '@react-oauth/google';

import SendSMSFlow from './components/SendSMSFlow';
import {FAST_FORWARD_AUTH_QPARAM, GOOGLE_OAUTH_SCOPES} from './constants';
import {useAuthentication} from './contexts/AuthenticationContext';
import './App.css';
import LocalServiceWorkerRegister from './serviceWorkerRegister';
import {ReactComponent as GoogleIcon} from './icons/google.svg';


import weekday from 'dayjs/plugin/weekday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import isToday from 'dayjs/plugin/isToday'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import weekOfYear from 'dayjs/plugin/weekOfYear'
// import utc from 'dayjs/plugin/utc'

dayjs.extend(weekday)
dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.extend(isSameOrBefore)
dayjs.extend(weekOfYear)
// dayjs.extend(utc)

// https://developers.google.com/calendar/api/guides/auth

const whatsappMsgEncoded = `I+am+interested+in+SMScalendar.xyz%21`
const whatsappPhoneNumber = '0040748049693'

/**
 * Checks for 'ff' flag and automatically authenticates.
 */
const postAuthorizationIncrement = () => {
  let searchParams = new URLSearchParams(window.location.search)

  if (searchParams.has(FAST_FORWARD_AUTH_QPARAM)) {
    const url = new URL(window.location.href);

    url.searchParams.delete(FAST_FORWARD_AUTH_QPARAM);
    window.history.pushState(null, document.title, url);

    document.querySelector('.o-auth-btn').click()
  }
}

async function getGoogleTokens(code) {
  const url = `${process.env.REACT_APP_AUTH_SERVICE}/google/token`;
  const opts = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({code})
  }
  const response = await fetch(url, opts);
  return response.json();
}

function App() {
  const [loading, setLoading] = useState()
  const [revision, setRevision] = useState()
  const {authentication, onSignIn} = useAuthentication()

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope: GOOGLE_OAUTH_SCOPES,
    onSuccess: async (codeResponse) => {
      const tokens = await getGoogleTokens(codeResponse.code)
      await onAuthResponse(tokens)
      setLoading(false)
    },
    onError: errorResponse => {
      setLoading(false)
      console.error(`Google login error: ${errorResponse}`)
    },
    onNonOAuthError:	(nonOAuthError) => {
      setLoading(false)
      console.error(`NonGoogle login error: ${nonOAuthError}`)
    }
  });

  const onLogout = () => {
    googleLogout();
    window.location.reload();
  }

  const menu = useMemo(() => {
    return (
      <div style={{
        position: 'fixed',
        top: '10px',
        right: '10px',
      }}>
        <Menu menuAlign="end" size="small" >
          <MenuItem onClick={onLogout}>
            Logout
          </MenuItem>
        </Menu>
      </div>
    )
  }, [])

  useEffect(() => {
    bumpRevision()
    setup()
    postAuthorizationIncrement()
  }, [])

  const bumpRevision = () => setRevision(Date.now())

  const setup = () => {
    LocalServiceWorkerRegister()
  }
  const onAuthResponse = async (tokens) => {
    await onSignIn(tokens)
  }

  const renderAuthorized = () => {
    if (!authentication) {
      return null
    }

    return (<>
        {menu}
        <SendSMSFlow
          key={revision}
          onCompletion={bumpRevision}
        />
      </>
    )
  }

  const renderFooter = () => {
    return <Text variation={"info"} fontSize=".85rem">
      <br/>
      <br/>
      Would you like to join the closed BETA?<br/>
      Reach out over:<br/>
      <Link textDecoration="none"
            href={`mailto:bogdam.maier1@gmail.com?subject=${whatsappMsgEncoded}`}>
        Email
      </Link>{`, `}
      <Link textDecoration="none"
            href="https://twitter.com/BogdanM0">
        Twitter
      </Link>{' or '}
      <Link textDecoration="none"
            href={`https://wa.me/${whatsappPhoneNumber}?text=${whatsappMsgEncoded}`}>
        Whatsapp
      </Link>
    </Text>
  }

  const renderUnauthorized = () => {
    if (authentication) {
      return null
    }

    return <>
      <Image
        width={500}
        alt="flowers-image"
        src={'/flowers.png'}
      />
      <Button className="o-auth-btn" style={{marginTop: '20px'}}
              disabled={loading}
              onClick={() => {
                setLoading(true)
                googleLogin()
              }}>
        <div style={{width: '20px', marginRight: '10px'}}>
          {!loading && <GoogleIcon />}
          {loading && <Loader />}
        </div>
        Sign in with Google
      </Button>

      {renderFooter()}
    </>
  }

  return (<div className="App">
    {loading ? <Placeholder size="small"/> : <Placeholder size="small" style={{visibility: 'hidden'}}/>}

    <Flex width="100%"
          direction="column"
          alignItems="center"
          gap="1rem"
    >
      <Heading level={3}>
        <Text variation="primary" color="#176b68">
          SMSCalendar.xyz
          <Text variation="success" fontSize=".9rem"
                style={{textAlign: 'end', marginRight: '-20px', marginTop: "-8px"}}>
            BETA
          </Text>
        </Text>
      </Heading>
    </Flex>

    <Text variation="secondary"
          fontSize=".8rem" marginTop="-12px">
      Notify your appointments over SMS
    </Text>
    <Divider orientation="horizontal" style={{visibility: 'hidden', margin: '10px'}}/>

    <Flex
      direction="column"
      alignItems="center"
      style={{height: '85vh'}}
    >
      <div className="App-content">
        {renderAuthorized()}
        {renderUnauthorized()}
      </div>
    </Flex>
    <Text variation="info"
          style={{
            fontSize: '12px',
            position: 'sticky',
            bottom: '30px',
          }}>Version: {process.env.REACT_APP_GIT_SHA || 'Development'}</Text>
  </div>)
}

export default App
