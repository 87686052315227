/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */

export default function LocalServiceWorkerRegister() {
    if (!('serviceWorker' in navigator)) {
        console.error('Service Worker is not supported in this browser.');
    }

    if (window?.Notification?.requestPermission) {
        window.Notification.requestPermission(function (status) {
            console.log('> Notification permission status:', status);
        });
    }

    if('serviceWorker' in navigator) {
        const path = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js`

        navigator.serviceWorker
            .register(path)
            .then(async (registration) => {
                console.log(`> Service Worker (${path}) is registered.`);
            });
    } else {
        console.log('Service Worker not supported')
    }
}

