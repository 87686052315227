import { DF_LABS_SMS_API_KEY } from '../constants';

export const sendSms = (body) => {
    const url = `${process.env.REACT_APP_SMS_API_URL}/v1/send?t=${DF_LABS_SMS_API_KEY}` // TODO process.env
    const headers = new Headers();
    headers.append("Content-Type", "application/json;charset=UTF-8");
    headers.append("Access-Control-Allow-Headers", "Origin, Content-Type, Accept, Access-Control-Allow-Origin");
    headers.append('Access-Control-Allow-Origin', window.location.origin);

    const requestOptions = {
        method: 'POST',
        redirect: 'follow',
        mode: 'cors',
        body: JSON.stringify(body),
        headers
    };

    return fetch(url, requestOptions)
        .then(async (response) => {
            const resp = await response.json()
            return {
                ...body,
                ...resp,
                smsId: resp.id
            }
        })
        .catch(error => {
            console.error('error:', error);
            // throw error;
        });
}
