import dayjs from 'dayjs';

export const smsTemplate = (template, startStr, diffStr, sender = '') =>
  `${template}, ${startStr}, ${sender}` // TODO add optional duration  -${diffStr}h

export const buildSMS = (template, sender, {start, end}) => {
  const startStr = start.format('DD MMMM@HH:mm')
  const diffStr = end.diff(start, 'hour')
  const msg = smsTemplate(template, startStr, diffStr, sender)

  // remove diacritics TODO support?
  return msg.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

}

function calculateScheduleTimestamp(eventStartDate, now, after5minTS) {
  const isInTheNextDay = eventStartDate.isAfter(now.add(1, 'day')) /*&& eventStartDate.isBefore(now.add(2, 'day'))*/
  const oneDayAheadTS = eventStartDate.subtract(1, 'day')
  const isImmediately = eventStartDate.isAfter(now) && eventStartDate.isToday() && eventStartDate.isAfter(now.add(30, 'minute'))

  return isImmediately
    ? after5minTS
    : isInTheNextDay
      ? oneDayAheadTS : null
}

function buildMessage(scheduledAtTS, phoneNumbers, flowState, email, startDate, dateEnd, eventId) {
  // const isNotInRangeOfScheduling = startDate.isAfter(dayjs().add(2, 'day'))
  const isNotInRangeOfScheduling = startDate.isAfter(dayjs().add(1, 'day'))

  return {
    eventId,
    // CREATED if it is
    // PENDING waiting to be scheduled
    // SCHEDULED after a schedule request has been done to sms api
    status: isNotInRangeOfScheduling ? 'pending' : 'created',
    phoneNumbers,
    scheduledAtTS: scheduledAtTS?.unix() * 1000 || null,
    scheduledAt: scheduledAtTS?.format() || null,
    sms: buildSMS(
      flowState.template,
      flowState.senderName,
      {start: startDate, end: dateEnd,}),
    startDate: startDate.format(),
  };
}

export const buildMessages = (flowState) => {
  const now = dayjs()
  const after5min = dayjs().add(5, 'minute');

  // build messages from events with contacts/connections
  let messagesWithContacts = flowState.events.map(({
       id,
       attendees = [],
       start: {dateTime: dateTimeStart},
       end: {dateTime: dateTimeEnd},
    }) => {
      return attendees.map(({email}) => {
        const startDate = dayjs(dateTimeStart);
        const dateEnd = dayjs(dateTimeEnd);
        const scheduledAtTS = calculateScheduleTimestamp(startDate, now, after5min);
        const {phoneNumbers} = flowState.subjectsMap?.[email];

        if (scheduledAtTS === null) {
          console.error(`>  `, `scheduledAtTS is null for ${email} ${startDate.format()}`) // TODO
          return null // gets filtered out
        }

        return buildMessage(
          scheduledAtTS,
          phoneNumbers,
          flowState,
          email,
          startDate,
          dateEnd,
          id,
        );
      }).filter(Boolean)
  })

  // messages from events with no contacts/connections
  let messagesFromSubjectData = Object.entries(flowState.subjectsMap)
    .map(([eventKey, {phoneNumbers}]) => {
      if (eventKey.includes('@')) { // skip email keys
        return null
      }
      const events = flowState.events.filter(({
        summary,
        start: {
          dateTime: startDateTime,
          date: startDate
        }
      }) => startDateTime === eventKey || startDate === eventKey || summary === eventKey);

      return events.map((event) => {
        const startDate = dayjs(event.start.dateTime || event.start.date);
        const dateEnd = dayjs(event.end.dateTime || event.start.date);
        const scheduledAtTS = calculateScheduleTimestamp(startDate, now, after5min);

        return buildMessage(
          scheduledAtTS,
          phoneNumbers,
          flowState,
          '',
          startDate,
          dateEnd,
          event.id,
        )
      })
    })
    .filter(Boolean)
  // flatten arrays
  messagesWithContacts = messagesWithContacts.flat(1)
  messagesFromSubjectData = messagesFromSubjectData.flat(1)

  const allMessages = messagesWithContacts.length + messagesFromSubjectData.length;
  messagesWithContacts = messagesWithContacts.filter(item => { // TODO filter all messages that are not valid for sms sending
    return item.phoneNumbers?.length
  })
  messagesWithContacts = [...messagesWithContacts, ...messagesFromSubjectData]

  if (allMessages - messagesWithContacts.length !== 0) {
    alert(`Some of the attendees have no phone numbers, therefore ${allMessages - messagesWithContacts.length} messages will not be sent`)
  }
  return messagesWithContacts
}

// GSM network specific logic
const gsmCodePoints = Object.freeze(new Set([
  0x000a, 0x000c, 0x000d,
  0x0020, 0x0021, 0x0022, 0x0023, 0x0024, 0x0025, 0x0026, 0x0027, 0x0028, 0x0029, 0x002a, 0x002b, 0x002c, 0x002d, 0x002e, 0x002f,
  0x0030, 0x0031, 0x0032, 0x0033, 0x0034, 0x0035, 0x0036, 0x0037, 0x0038, 0x0039, 0x003a, 0x003b, 0x003c, 0x003d, 0x003e, 0x003f,
  0x0040, 0x0041, 0x0042, 0x0043, 0x0044, 0x0045, 0x0046, 0x0047, 0x0048, 0x0049, 0x004a, 0x004b, 0x004c, 0x004d,
  0x004e, 0x004f,
  0x0050, 0x0051, 0x0052, 0x0053, 0x0054, 0x0055, 0x0056, 0x0057, 0x0058, 0x0059, 0x005a, 0x005b, 0x005c, 0x005d, 0x005e, 0x005f,
  0x0061, 0x0062, 0x0063, 0x0064, 0x0065, 0x0066, 0x0067, 0x0068, 0x0069, 0x006a, 0x006b, 0x006c, 0x006d, 0x006e, 0x006f,
  0x0070, 0x0071, 0x0072, 0x0073, 0x0074, 0x0075, 0x0076, 0x0077, 0x0078, 0x0079, 0x007a, 0x007b, 0x007c, 0x007d, 0x007e,
  0x00a1, 0x00a3, 0x00a4, 0x00a5, 0x00a7,
  0x00bf,
  0x00c4, 0x00c5, 0x00c6, 0x00c7, 0x00c9,
  0x00d1, 0x00d6, 0x00d8, 0x00dc, 0x00df,
  0x00e0, 0x00e4, 0x00e5, 0x00e6, 0x00e8, 0x00e9, 0x00ec,
  0x00f1, 0x00f2, 0x00f6, 0x00f8, 0x00f9, 0x00fc,
  0x0393, 0x0394, 0x0398, 0x039b, 0x039e, 0x03a0, 0x03a3, 0x03a6, 0x03a8, 0x03a9,
  0x20ac,
]));

export const isGsmMessage = (message) => {
  if (!message?.length) {
    return false;
  }
  for (const s of message) {
    const codePoint = s.codePointAt(0);
    if (codePoint && !gsmCodePoints.has(codePoint)) {
      return false;
    }
  }
  return true;
}
