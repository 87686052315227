import * as PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import {Button, Flex} from '@aws-amplify/ui-react';
import {MultiStepForm, Step} from 'react-multi-form';
import {useServices} from '../contexts/ServiceContext';
import {buildMessages} from '../services/sms-builder';
import {sendSms} from '../services/sms-service';
import {CALENDAR_ID} from '../constants';
import {useMetaConfig} from '../useMetaConfig';
import {Report} from './Report';
import {SubjectsPreview} from './SubjectsPreview';
import CalendarPicker from './CalendarPicker';
import TemplateEditor from './TemplateEditor';
import Toasts from './Toasts';
import {WeekAnalytics} from './WeekAnalytics';
import FirebaseService from '../services/FirebaseService';

function SendSmsFlow({onCompletion}) {
  const [reportData, setReportData] = useState({successes: [], failures: []})
  const [showWeeklyAnalitycs, setShowWeeklyAnalytics] = useState(false)
  const [progress, setProgress] = useState(1)
  const [flowState, setFlowState] = useState({})
  const [currentCalendarId, setCurrentCalendarId] = useMetaConfig({
    keyName: CALENDAR_ID,
    defaultValue: false,
    cloudBackup: true
  })
  const {firebase} = useServices()


  const updateFlowState = ({calendarId, template, events, subjectsMap, senderName, hasContentError}) => {
    const newState = {
      ...flowState,
      ...(calendarId && {calendarId}),
      ...(senderName && {senderName}),
      ...(hasContentError && {hasContentError}),
      ...(template && {template}),
      ...(events && {events}),
      ...(subjectsMap && {subjectsMap}),
    }
    if (calendarId) {
      setCurrentCalendarId(calendarId)
    }
    setFlowState(newState)
  }
  const isBackDisabled = () => progress === 1
  const isNextDisabled = useMemo(() => {
    switch (progress) {
      case 1:
        return !flowState.calendarId
      case 2:
        return !flowState.template
      default:
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowState.calendarId, flowState.template, flowState.subjectsMap])

  const proceed = (increment) => setProgress(progress + increment)

  const doSchedule = async () => {
    try {
      // const contacts = Object.entries(flowState.subjectsMap)
      // const allEmailsHavePhone = contacts.every(([email, {phoneNumbers = []}]) => phoneNumbers?.length)
      // let acknowledge;
      //
      // if (!allEmailsHavePhone) {
      //     acknowledge = window.confirm('There are missing phone numbers, press  "Cancel" to return and set them up or "Ok" to schedule by ignoring those')
      //
      //     if (!acknowledge) return
      // }
      const {email} = FirebaseService.getProfile();
      const messages = buildMessages(flowState)

      let pending = messages.filter(e => e.status === 'pending')
      let schedulingMessages = messages.filter(e => e.status !== 'pending' &&  e.status !== 'scheduled')

      const promises = schedulingMessages.map((smsMessageData) => {
        return sendSms({
          ...smsMessageData,
          email,
        })
      });

      const requestResponseObjects = await Promise.allSettled(promises)
      const success = requestResponseObjects.filter(({status}) => status === 'fulfilled').map(({value}) => ({
        ...value,
        status: 'scheduled',
      }))
      const failed = requestResponseObjects.filter(({status}) => status === 'rejected').map(({value}) => ({
        ...value,
        status: 'failed',
      }))

      const weekActivity = [...success, ...failed, ...pending].reduce((acc, messageData) => {
        acc[messageData.eventId] = messageData
        return acc
      }, {});
      await firebase.appendWeekActivity(weekActivity);

      // if scheduledAtTS is -1 there was an issue
      const messagesWithMinusOneTS = messages.filter(e => e.scheduledAtTS === -1)
      setReportData({
        successes: success.length - messagesWithMinusOneTS.length,
        pending: pending.length,
        failures: failed.length + messagesWithMinusOneTS.length
      })

      proceed(1)
    } catch (e) {
      console.error(e)
      alert('Error during message scheduling ')
    }
  }

  return (<>
    {progress < 4 && <MultiStepForm activeStep={progress} style={{width: '84%'}}>
      <Step label="Calendar">
        {progress === 1 && <CalendarPicker calendarId={currentCalendarId || flowState?.calendarId}
                                           onSubmit={updateFlowState}/>}
      </Step>
      <Step label="Message">{progress === 2 && <TemplateEditor showPreview={flowState?.template}
                                                               onSubmit={updateFlowState}/>}
      </Step>
      <Step label="Receivers">
        {progress === 3 && <SubjectsPreview calendarId={flowState?.calendarId}
                                            sender={flowState?.senderName}
                                            onSubmit={updateFlowState}/>}
      </Step>
    </MultiStepForm>}
    <Toasts/>
    {progress !== 4 && <Flex justifyContent={'space-around'} width={'100%'} marginTop="20px">
      <div>
        {<Button variation="link"
                 disabled={isBackDisabled()}
                 onClick={() => proceed(-1)}>
          Previous
        </Button>}
      </div>
      {(progress !== 3) && <Button variation="link"
                                   disabled={isNextDisabled}
                                   onClick={() => proceed(1)}>
        Next
      </Button>}
      {(progress === 3) && <Button variation="link"
                                   onClick={() => doSchedule()}>
        Schedule
      </Button>}
    </Flex>}
    <Button variation="link"
            color="#909090"
            fontWeight={400}
            onClick={() => setShowWeeklyAnalytics(true)}>
      Week Analytics
    </Button>

    {progress === 4 && <Report successes={reportData.successes}
                               failures={reportData.failures}
                               pending={reportData.pending}
                               onCompletion={onCompletion}/>}

    {showWeeklyAnalitycs && <WeekAnalytics onHide={() => setShowWeeklyAnalytics(false)}/>}
  </>)
}

SendSmsFlow.propTypes = {
  onCompletion: PropTypes.func.isRequired,
}

export default SendSmsFlow;
