import { useState } from 'react';
import FirebaseService from './services/FirebaseService';

export const useMetaConfig = ({keyName, defaultValue, cloudBackup }) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            let value = window.localStorage.getItem(keyName);
            console.log(`useMetaConfig: ${keyName} local: ${value}`);

            if (cloudBackup) {
                FirebaseService
                  .getMetaConfigValue(keyName)
                  .then((val) => {
                    console.log(`useMetaConfig: ${keyName} remote: ${value}`);
                    setValue(val)
                });
            }

            if (value) {
                return JSON.parse(value);
            } else {
                window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
                return defaultValue;
            }
        } catch (err) {
            return defaultValue;
        }
    });

    const setValue = newValue => {
        try {
            window.localStorage.setItem(keyName, JSON.stringify(newValue));

            if (cloudBackup && newValue !== storedValue) {
               FirebaseService.updateMetaConfig({[keyName]: newValue });
            }
        } catch (err) {}
        setStoredValue(newValue);
    };

    const clearValue = () => {
        try {
            window.localStorage.removeItem(keyName);

            if (cloudBackup ) {
                FirebaseService.updateMetaConfig({[keyName]: undefined });
            }
        } catch (err) {console.error(err)}
    };

    return [storedValue, setValue, clearValue];
};
