export const TEMPLATE_KEY = 'sc_last_used_template'
export const REMEMBER_KEY = 'sc_remember_template'
export const CALENDAR_ID = 'sc_last_calendar_id'
export const SENDER_NAME = 'sc_last_sender_name'

/* GSM character set */
export const MAX_SMS_CHARACTERS = 160;

/* UNICODE character set */
export const MAX_SMS_CHARACTERS_UNICODE = 70;

export const COUNTRY_CODES = ['+40'];


export const FAST_FORWARD_AUTH_QPARAM = 'ff'


export const GOOGLE_OAUTH_SCOPES ='profile email https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/contacts https://www.googleapis.com/auth/user.phonenumbers.read'

export const DF_LABS_SMS_API_KEY = 'cGl6ZGFtYXRpCg=='


