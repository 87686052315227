import {gapi} from 'gapi-script';
import {initClient} from './initClient';


export const searchContacts = async (targetEmails, targetNames, token) => {
  await initClient(token, 'people', 'v1');

  return new Promise((resolve, reject) => {
    gapi.client.people.people.connections.list({
      resourceName: 'people/me',
      personFields: 'phoneNumbers,names,emailAddresses',
      pageSize: 1000,
    }).then(({body}) => {
      const response = JSON.parse(body)

      // Filter by email or name
      const filteredConnections = (response.connections || []).filter(connection => {
        const emails = connection.emailAddresses?.map(email => email.value.toLowerCase());
        const names = connection.names?.map(name => name.displayName.toLowerCase());

        // Check if any name matches
        const hasNameMatch = names && targetNames.some(targetName =>
          names.includes(targetName.toLowerCase())
        );

        // Check if any email matches
        const hasEmailMatch = emails && targetEmails.some(targetEmail =>
          emails.includes(targetEmail.toLowerCase())
        );
        return hasNameMatch || hasEmailMatch;
      });
      resolve(filteredConnections);
    }).catch(err => {
      reject(err);
    });
  });
};
