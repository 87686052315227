import React, { memo, useEffect, useState } from 'react';
import { Text, Radio, RadioGroupField, Loader } from '@aws-amplify/ui-react';
import { useAuthentication } from '../contexts/AuthenticationContext';
import { useServices } from '../contexts/ServiceContext';
import { getCalendars } from '../services/GCalendar';

/**
 * CalendarPicker type: https://developers.google.com/calendar/api/v3/reference/calendars#resource-representations
 * CalendarPicker event type: https://developers.google.com/calendar/api/v3/reference/calendars#resource-representations
 * @return {JSX.Element}
 * @constructor
 */
const CalendarPicker = ({ calendarId, onSubmit}) => {
    const [isLoading, setIsLoading] = useState(true)
    const [calendars, setCalendars] = useState([])
    const {getToken} = useAuthentication()
    const { setLastError } = useServices()

    useEffect(() => {
        calendarId && onSubmit({ calendarId }) // update Sendflow
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []   )

    useEffect(() => {
        const fn = async () => {
            try {
                const calendars = await getCalendars(getToken())
                setIsLoading(false)
                setTimeout(() => setCalendars(calendars), 500)
            } catch (err) {
                if (err.status === 403) {
                    setLastError('403 calendar access forbidden')
                }
                console.error(err)
            }
        }

        fn();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getToken])

    return (
        <div className={'calendar-picker'}>
            <RadioGroupField label={<Text variation={"info"} level={6}>Pick from available calendars</Text>}
                             name="calendars"
                             value={calendarId}
                             onChange={({ target : { value }}) => {
                                 onSubmit({ calendarId: value })
                             }}>

                {!isLoading && calendars.map(calendar => {
                    return <Radio value={calendar.id}
                                  name={calendar.id}
                                  key={calendar.id}
                                  fontSize=".7rem">
                        {calendar.summaryOverride || calendar.summary || calendar.id}
                    </Radio>
                })}
            </RadioGroupField>

            {isLoading && <Loader variation="linear" /> }
        </div>);
};

export default memo(CalendarPicker);
