import {gapi} from 'gapi-script';
import {GOOGLE_OAUTH_SCOPES} from '../constants';


export const incrementAuthorization = async (onSuccess, onFailure) => {
  let Auth = gapi.auth2.getAuthInstance();

  if (Auth === null || !Auth.isSignedIn.get()) {
    gapi.auth2.init({
      client_id: process.env.REACT_APP_GAPI_CLIENT_ID,
      scope: GOOGLE_OAUTH_SCOPES,
      // access_type: 'offline' // 'code'
      // access_type: 'none' // 'code'
      // cookie_policy: cookiePolicy,
      // login_hint: loginHint,
      // hosted_domain: hostedDomain,
      // fetch_basic_profile: fetchBasicProfile,
      // discoveryDocs,
      // ux_mode: uxMode,
      // redirect_uri: redirectUri,
    });

    Auth = gapi.auth2.getAuthInstance();
  }

  return new Promise((resolve, reject) => {
    Auth.grantOfflineAccess({
      scope: GOOGLE_OAUTH_SCOPES
    }).then(
      res => onSuccess(res),
      err => onFailure(err)
    )
  })
}


export const invalidateOAuth = async (onSuccess, onFailure) => {
  debugger
  const Auth = gapi.auth2.getAuthInstance()

  return new Promise((resolve, reject) => {
    Auth.signOut();
    Auth.disconnect();

    window.location.assign('/');
  })
}
